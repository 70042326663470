<style type="text/css">
    @import "~@/assets/css/common.css";
</style>
<style scoped>
    .msgName {
        min-width: 120px;
        width: 120px;
    }

    .msgVal {
        line-height: 20px;
        white-space: normal;
    }

    .state-reason {
        height: 100px;
        overflow-y: auto;
    }

    /deep/ .el-select .el-input__inner {
        border-color: rgb(23, 118, 210);
        height: 30px
    }
</style>
<script>
    import Layout from "@/views/layouts/main";
    import CheckHeader from "@/components/check-header";
    import ChangeScore from "@/components/change-score.vue"
    import {
        getReview,
        batchReview,
        singleReview,
        getReviewDetail,
        deleteReview
    } from "@/api/score/review.js"
    import {
        getCjk,
    } from "@/api/score/scoreKu.js";

    /**
     *成绩复核管理
     */
    export default {
        components: {
            Layout,
            CheckHeader,
            ChangeScore


        },
        data() {
            return {
                title: "成绩复核管理  /  ",
                title2: "2021年二级造价工程师职业资格增报专业考试成绩库",
                subTitle: "切换库",
                items: [{
                    text: "用户首页",
                    href: "/admin"
                },
                    {
                        text: "控制面板",
                        active: true
                    }
                ],
                isIndeterminate: false,
                showBatch: false,
                editReview: false,
                checkList: [],
                tableList: [],
                score: "",
                fhjg: [{
                    name: "未复核",
                    val: "0",
                },
                    {
                        name: "待发布",
                        val: "1",
                    },
                    {
                        name: "已复核",
                        val: "2",
                    },
                ],
                isDifferent: [{
                    name: "全部",
                    val: "false",
                },
                    {
                        name: "差异",
                        val: "true",
                    },],
                exportTitle: "成绩复核导出",
                importTitle: "成绩复核导入",
                module: "CJFH",
                importModule: "FHCJK",
                addForm: {},
                editForm: {},
                cjfs: "",
                ksbmbh: "",
                pageData: {
                    pageNum: 1,
                    pageSize: 20,
                    total: 0,
                    keyWord: "",
                    fhjg: "",
                    cjkid: "",
                    cxfh: 0

                },
                isShow: false,
                pclfw:"1"
            };
        },
        methods: {
            getList() {

                getReview(this.pageData).then(res => {
                    if (res.status) {
                        if (res.status) {
                            this.tableList = res.data
                            this.pageData.total = res.total

                        }
                    }
                })
            },
            searchClick() { //搜索时先将分页的页码归1
                this.pageData.pageNum = 1; //当前页

                this.getList()


            },
            showDiff(e){
                console.log(e)
                if(e=="0"){
                    this.isShow=false
                    delete this.pageData.isDifferent
                }else{
                    this.isShow=true

                }


            },
            handleSizeChange(val) {
                this.pageData.pageSize = val
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageData.pageNum = val
                this.getList();
            },
            // 全选
            checkAll(e) {
                this.checkList = e ? this.tableList.map(item => {
                    return item.sid
                }) : [],
                    this.isIndeterminate = e
            },
            // 批量复核弹窗
            handleBatch() {
                this.addFrom = {}
            },
            // 批量复核
            submitBatch(e) {
                console.log(e)
                let sids
                let paramObj={}
                let newObj={
                    keyWord: this.pageData.keyWord,
                    fhjg: this.pageData.fhjg,
                    cjkid: this.pageData.cjkid,
                    isDifferent: this.pageData.isDifferent
                }


                    if(this.pclfw==1){
                        let newArr=[]
                        this.tableList.forEach(k=>{
                            newArr.push(k.sid)
                        })
                        sids = newArr.join(",")
                        paramObj={
                            ...newObj,
                            sids:sids,
                            pcljg:e,
                            pclfw:this.pclfw

                        }
                        this.batchAll(paramObj)
                    }else{
                        if (this.checkList.length > 0) {
                            sids = e.sid ? e.sid : this.checkList.join(",")
                        paramObj={
                            sids:sids,
                            pcljg:e,
                            pclfw:this.pclfw
                        }
                            this.batchAll(paramObj)
                    }                else {
                    this.$message({
                        type: "warning",
                        message: '未选择数据',
                    });
                }}

            },
            //批量
            batchAll(paramObj){
                batchReview(paramObj).then(res => {
                    if (res.status) {
                        this.$message({
                            type: "success",
                            message: "批量复核成功！",
                        });
                        this.showBatch = false
                        this.getList();
                    }
                })
            },

            //编辑
            editItem(sid) {
                this.editReview = true
                this.editForm = {}
                getReviewDetail(sid).then(res => {
                    if (res.status) {
                        this.editForm = res.data
                        this.editForm.sid = res.data.sid
                        this.cjfs = res.data.cj
                    }
                })
            },
            // changeFhjg() {
            //   if (this.editForm.fhjg == 1) {
            //     this.editForm.fhcj = this.cjfs
            //   } else {
            //     this.editForm.fhcj = ""
            //   }
            // },
            // 复核提交
            submitReview() {
                if (this.editForm.fhcj) {
                    this.editForm.fhjg = 1
                    let data = {
                        sid: this.editForm.sid,
                        fhcj: this.editForm.fhcj,
                        fhjg: this.editForm.fhjg,
                        fhbz: this.editForm.fhbz
                    }
                    singleReview(data).then(res => {
                        if (res.status) {
                            this.$message({
                                type: "success",
                                message: res.message ? res.message : '成绩复核成功！',
                            });
                            this.editReview = false
                            this.getList()
                        }
                    })
                } else {
                    this.editForm.fhjg = 0
                    this.$message({
                        type: "warning",
                        message: '请先填写复核分数！',
                    });
                }

            },
            // 删除
            deleteItem(sid, text) {
                this.$confirm(`此操作将永久删除该【${text}】的成绩复核数据, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteReview(sid).then(res => {
                        if (res.status) {
                            this.$message({
                                type: 'success',
                                message: '数据删除成功!'
                            });
                        }
                        this.getList()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },


            getLocalScore() { // 获取local的成绩库
                this.score = this.getStore("Score")
                if (this.sid) {
                    this.pageData.cjkid = this.sid
                    this.getList()
                    return
                    //取sid
                } else if (this.score) {
                    let score = JSON.parse(this.score)
                    this.title2 = score.ksmc
                    this.pageData.cjkid = score.sid
                    this.ksbmbh = score.ksbmbh
                    this.getList()
                    // 取locacal
                } else {
                    let search = {
                        nf: new Date().getFullYear(),
                    }
                    getCjk(search).then((res) => {
                        if (res.status && res.data.length) {
                            this.sid = res.data[0].sid;
                            if (!this.pageData) {
                                this.pageData = {}
                            }
                            this.pageData.cjkid = res.data[0].sid;
                            this.ksbmbh = res.data[0].ksbmbh
                            this.title2 = res.data[0].ksmc
                            this.getList()
                        }

                    });
                }

            },


            uploadFile() {
                var _this = this;
                _this.$importModal().show({
                    title: this.importTitle,
                    module: this.importModule,
                    sid: this.pageData.cjkid,
                    success: this.getList
                })
            },

        },
        mounted() {
            this.getLocalScore()

        },
        created() {
        }
    };
</script>

<template>
    <Layout>
        <CheckHeader :title="title" :title2="title2" :subTitle="subTitle" :items="items"/>

        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="min-height: 600px;">
                    <div class="card-body">
                        <div class="pb-3 border-dash check-table-top">
                            <div class="flexList" style="flex: 1;">
                                <el-select class=" mr-2 " style="width: 12%;" clearable placeholder="请选择复核结果"
                                           size="small" @change="showDiff($event)"
                                           v-model="pageData.fhjg">
                                    <el-option v-for="(item, i) in fhjg" :label="item.name" :value="item.val"
                                               :key="i"></el-option>
                                </el-select>
                                <el-select class=" mr-2"  v-if="isShow" style="width: 10%;" clearable
                                           placeholder="请选择差异"
                                           size="small"
                                           v-model="pageData.isDifferent">
                                    <el-option v-for="(item, i) in isDifferent" :label="item.name" :value="item.val"
                                               :key="i"></el-option>
                                </el-select>
                                <input placeholder="关键字" class="h30 form-control border-blue mr-2 w-10" maxlength="50"
                                       v-model="pageData.keyWord"/>
                                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                                        class="iconfont mr-2 icon-mb-search"></i>查询
                                </button>
                                <b-button v-b-modal.modal-center variant="outline-info"
                                          class="flexList w-md condition mr-2"
                                          @click="handleBatch()"><i class="iconfont icon-full-selection mr-2"></i>批量处理
                                </b-button>



                                <el-button size="small" type="primary" plain class="flexList mr-2 h30 "
                                           @click="uploadFile"><i
                                        class="iconfont icon-riLine-upload-2-line mr-2"></i>导入复核成绩
                                </el-button>

                            </div>
                            <div class="d-flex">
                                <div class="border-blue export-tab"
                                     @click="$exportModal().show({title:title2+exportTitle, type: 'xlsx', module:module,condition:pageData})">
                                    <i class="iconfont icon-antOutline-file-excel mr-2"></i>excel
                                </div>
                                <div class="border-blue export-tab"
                                     @click="$exportModal().show({title: title2+exportTitle, type:'dbf', module:module,condition:pageData})">
                                    <i class="iconfont icon-data mr-2"></i>dbf
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive border mt-3">
                            <el-checkbox-group v-model="checkList">
                                <table class="table light-table table-hover table-bordered ">
                                    <thead class="thead-light">
                                    <tr>
                                        <th style="width: 4%;">
                                            <el-checkbox :indeterminate="isIndeterminate" @change="checkAll"
                                                         class="mr-1"></el-checkbox>
                                        </th>
                                        <th>成绩库名称</th>
                                        <th style="width: 6%;">姓名</th>
                                        <th style="width: 7%;">证件号码</th>
                                        <th style="width: 7%;">准考证号</th>
                                        <th>科目名称</th>
                                        <th style="width: 5%;">成绩序号</th>
                                        <th style="width: 4%;">成绩</th>
                                        <th style="width: 5%;">复核结果</th>
                                        <th style="width: 5%;">复核成绩</th>
                                        <th style="width: 12%;">申请时间</th>
                                        <th style="width: 5%;">操作</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item,index) in tableList" :key="index">
                                        <td>
                                            <el-checkbox :label="item.sid">
                                                {{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                                            </el-checkbox>
                                        </td>
                                        <td>{{item.ksmc}}</td>
                                        <td><a href="" class="blue-font">{{item.xm}}</a></td>
                                        <td>
                                            {{item.sfzjh}}
                                        </td>
                                        <td>{{item.zkzh}}</td>
                                        <td>{{item.kmmc}}</td>
                                        <td>{{item.cjxh}}</td>
                                        <td>{{item.cj}}</td>
                                        <td>
                                            <div v-if="item.fhjg==0" class="text-danger"> 未复核</div>
                                            <div v-if="item.fhjg==1" class="text-warning">待发布</div>
                                            <div v-if="item.fhjg==2" class="text-success">已复核</div>
                                        </td>
                                        <td>{{item.fhcj}}</td>
                                        <td>{{item.sqsj}}</td>
                                        <td class="tab-icon "><i class="iconfont icon-edit-two mr-1"
                                                                 @click="editItem(item.sid)"></i> <i
                                                class="iconfont icon-riLine-delete-bin-line"
                                                @click="deleteItem(item.sid,item.xm)"></i></td>
                                    </tr>


                                    </tbody>
                                </table>
                            </el-checkbox-group>
                        </div>
                        <div class="float-right d-flex">
                            <el-pagination @size-change="handleSizeChange" background
                                           @current-change="handleCurrentChange"
                                           :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                                           layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
                            </el-pagination>
                        </div>

                    </div>
                </div>
            </div>


        </div>
        <!-- 弹窗开始 -->
        <!-- 切换成绩库 -->
        <ChangeScore></ChangeScore>

        <!-- 编辑 -->
        <b-modal id="editReview" v-model="editReview" title="成绩复核处理" size="lg" centered title-class="font-18"
                 hide-footer>
            <div>
                <div class="outerCotent mt-2 ">
                    <input type="hidden" class="msgValInput" value="" name="sid">
                    <div class="msgContent flexList fs-xs">
                        <div class="flexList col-sm-6 msgLabel">
                            <div class="msgName ">成绩库名称</div>
                            <div class="msgVal  flexList">
                                <div>{{editForm.ksmc}}</div>
                            </div>
                        </div>
                        <div class="flexList col-sm-6 msgLabel">
                            <div class="msgName ">淮考证号</div>
                            <div class="msgVal  flexList">
                                <div>{{editForm.zkzh}}</div>
                            </div>
                        </div>

                        <div class="flexList col-sm-6 msgLabel">
                            <div class="msgName ">姓名</div>
                            <div class="msgVal  flexList">
                                <div class="line1">{{editForm.xm}}</div>
                            </div>
                        </div>
                        <div class="flexList col-sm-6 msgLabel">
                            <div class="msgName ">身份证号</div>
                            <div class="msgVal  flexList">
                                <div class="line1">{{editForm.sfzjh}}</div>
                            </div>
                        </div>
                        <div class="flexList col-sm-6 msgLabel">
                            <div class="msgName ">报考单位</div>
                            <div class="msgVal  flexList w-33">
                                <div class="line1">{{editForm.bkdw}}</div>
                            </div>
                        </div>
                        <div class="flexList col-sm-6 msgLabel">
                            <div class="msgName ">报考职位</div>
                            <div class="msgVal  flexList w-33">
                                <div class="line1">{{editForm.bkxw}}</div>
                            </div>
                        </div>
                        <div class="flexList col-sm-6 msgLabel">
                            <div class="msgName ">申请复核科目</div>
                            <div class="msgVal  flexList">
                                <div class="line1">{{editForm.kmmc}}</div>
                            </div>
                        </div>

                        <div class="flexList col-sm-6 msgLabel">
                            <div class="msgName">复核前成绩</div>
                            <div class="msgVal  flexList">
                                <div class="line1">{{editForm.cj}}</div>
                            </div>
                        </div>

                        <div class="flexList w-100 msgLabel msgtextarea">
                            <div class="msgName flexList justify-content-center">原因陈诉</div>
                            <div class="msgVal">
                                <div class="mt-2">预估成绩:{{editForm.ygcj}}</div>
                                <div class="mr-2 state-reason pb-1">
                                    {{editForm.yycs}}
                                </div>
                            </div>
                        </div>
                        <div class="flexList w-100  msgLabel">
                            <div class="msgName">复核结果</div>
                            <div class="msgVal  flexList">
                                <div v-if="editForm.fhjg==0">未复核</div>
                                <div v-else>已复核</div>
                                <!-- <div class="form-check mr-2"><input type="radio" id="fhjg1" value="1" v-model="editForm.fhjg"
                                    class="form-check-input" @change="changeFhjg"><label for="fhjg1" class="form-check-label">复核无误
                                  </label></div>
                                <div class="form-check "><input type="radio" id="fhjg2" value="2" v-model="editForm.fhjg"
                                    class="form-check-input" @change="changeFhjg"><label for="fhjg2" class="form-check-label">复核有误
                                  </label></div> -->
                            </div>
                        </div>
                        <div class="flexList w-100  msgLabel">
                            <div class="msgName">复核后成绩</div>
                            <div class="msgVal  flexList">
                                <input class="msgValInput form-control " value="" v-model="editForm.fhcj">
                            </div>
                        </div>
                        <div class="flexList w-100  msgLabel" style="height:80px;">
                            <div class="msgName">备注</div>
                            <div class="msgVal  flexList">
                <textarea type="textarea" class="msgValInput form-control" style="height: 60px;" v-model="editForm.fhbz"
                          maxlength="500"></textarea>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class=" mt-3 text-center">
                <button type="button" class="btn btn-info h30  mr-2 w-md"
                        @click="submitReview">提交
                </button>
                <button type="button" class="btn btn-secondary h30  mr-2 w-md" @click="editReview=false">取消</button>
            </div>


        </b-modal>

        <!-- 批量处理 -->
        <b-modal id="modal-center" v-model="showBatch" centered title="成绩复核批处理" title-class="font-18" hide-footer>
            <div class="col-sm-10 p-0 mb-2 flexList">
                <el-radio v-model="pclfw" label="1">批处理本次查询内容 </el-radio>
                <el-radio v-model="pclfw" label="2">批处理勾选内容</el-radio>

            </div>

            <div class="red-font">您已批量选中数据  <span v-if="pclfw==1">{{pageData.total}}</span> <span v-else>{{checkList.length}}</span>  条，请选择操作</div>
            <div class=" mt-2">
                <div class="form-check "><input type="radio" name="exampleRadios" id="exampleRadios1" value="1"
                                                v-model="addForm.fhjg" class="form-check-input"><label
                        for="exampleRadios1"
                        class="form-check-label">批量处理成绩（成绩无变化）
                </label></div>
                <div class="form-check mt-2"><input type="radio" name="exampleRadios" id="exampleRadios3" value="2"
                                                    v-model="addForm.fhjg" class="form-check-input"><label
                        for="exampleRadios3"
                        class="form-check-label">批量设置为已复核（发布）
                </label></div>
                <div class="form-check mt-2"><input type="radio" name="exampleRadios" id="exampleRadios2"
                                                    v-model="addForm.fhjg"
                                                    value="0" class="form-check-input"><label for="exampleRadios2"
                                                                                              class="form-check-label">批量重置（未复核状态）</label>
                </div>
            </div>
            <div class="flexList mt-3 check-modal">
                <button type="button" class="btn btn-info h30  mr-2"
                        @click="submitBatch(addForm.fhjg)">批量设置
                </button>
                <button type="button" class="btn btn-secondary h30  mr-2" @click="showBatch=false">取消</button>
            </div>
        </b-modal>
        <!-- 弹窗结束 -->
    </Layout>
</template>
