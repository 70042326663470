// 成绩查询
import {
  getRequest,
  patchRequest,
  deleteRequest
} from "@/libs/axios";
// 分页查询
export const getReview = params => {
  return getRequest("/yethan/examReview/listPage", params);
};

// 单条复核
export const singleReview = params => {
  return patchRequest("/yethan/examReview/singleReview", params);
};
// 批量复核
export const batchReview = (params) => {
  return patchRequest(`/yethan/examReview/batchReview`,params);
};
// 详情
export function getReviewDetail(sid) {
  return getRequest('/yethan/examReview/getInfo/' + sid);
}
// 删除
export function deleteReview(sid) {
  return deleteRequest('/yethan/examReview/' + sid);
}
